import React, { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router'
import { CommonDialog } from './CommonDialog'

interface IErrorProps {
  errorDia: boolean
  setErrorDia: Dispatch<SetStateAction<boolean>>
  errorMsg: string
  errorHeaderMsg?: string
  isApprovalPolicy?: boolean
  onclick?: any
  isProceed?: boolean
}

export const ErrorDialog = ({
  errorDia,
  setErrorDia,
  errorMsg,
  errorHeaderMsg,
  isApprovalPolicy = false,
  onclick,
  isProceed,
}: IErrorProps) => {
  const history = useHistory()
  const validateAuthentication = () => {
    const lowerCaseErr = errorMsg?.toLowerCase()
    if (
      lowerCaseErr?.includes('not authenticated') ||
      lowerCaseErr?.includes('jwt')
    )
      return true
    else return false
  }

  return (
    <CommonDialog
      fullWidth={true}
      open={errorDia}
      onClose={
        validateAuthentication()
          ? () => {
              setErrorDia(false)
              localStorage?.removeItem('loggedInUser')
              localStorage?.removeItem('permission')
              history.push('/login')
            }
          : isApprovalPolicy
          ? () => onclick()
          : () => setErrorDia(false)
      }
      sections={{
        header: {
          title: validateAuthentication()
            ? 'Session Expired!'
            : !!errorHeaderMsg
            ? `${errorHeaderMsg}
              ${errorHeaderMsg?.includes('Error!') ? '' : '!'}`
            : 'Error!',
          style: { color: 'red' },
        },
        body: () => (
          <div className="content-wrap full text-noflow">
            <div className="mdDesc text-noflow">
              {validateAuthentication()
                ? 'Session has Expired/Not Authenticated!'
                : errorMsg}
            </div>
          </div>
        ),
        footer: {
          actions: isProceed
            ? [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Proceed',
                  props: {
                    onClick: () =>
                      isApprovalPolicy ? onclick() : setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ]
            : [
                {
                  displayText: 'Close',
                  props: {
                    onClick: validateAuthentication()
                      ? () => {
                          setErrorDia(false)
                          localStorage?.removeItem('loggedInUser')
                          localStorage?.removeItem('permission')
                          history.push('/login')
                        }
                      : isApprovalPolicy
                      ? () => onclick()
                      : () => setErrorDia(false),
                    // THE PREVIOUS CODE FOR REFERENCE
                    // onClick: () =>
                    //   isApprovalPolicy ? onclick() : setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
        },
      }}
    />
  )
}
